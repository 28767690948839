import './index.styl';

export default function NativeListingSpecStyles(Component) {
  return function NativeListingComponent(props) {
    return (
      <div className='native-listing-spec'>
        <Component {...props} />
      </div>
    );
  };
}

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';

import Topic from 'core/components/Topic';
import { Block, Section } from 'core/components/Grid';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import PageLoader from 'core/components/Loader/PageLoader';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import LightPageWrapper from 'site/components/LightPageWrapper';

import TopicHeader from 'site/pages/topic/TopicHeader';


function CommentsPage(props) {
  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <PageLoader />;

  return (
    <>
      <LightPageWrapper>
        <Topic content={data}>
          {topic => {
            const {
              id,
              attributes: {
                link,
                is_comments_disabled: isCommentsDisabled,
              },
            } = topic;

            return (
              <Section>
                <Block>
                  <TopicHeader
                    content={topic}
                    showComments={false}
                    showMedia={false}
                    showAnnounce={false}
                  />
                  <Link to={link} >
                    <Button size='small'>Вернуться к статье</Button>
                  </Link>
                  <Comments xid={id} isCommentsDisabled={isCommentsDisabled} />
                </Block>
                <InnerSideColumn />
              </Section>
            );
          }}
        </Topic>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

export default CommentsPage;

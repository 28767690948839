import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';
import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { Block, Section } from 'core/components/Grid';
import H1 from 'core/components/H1';
import ListPage from 'core/components/ListPage';

import { renderError } from 'core/utils/app-status-helper';

import RubricTopics from 'site/components/RubricTopics';
import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

import { RUBRICS_PAGE_LIMIT as LIMIT } from 'site/constants';

const fields = filterRequiredParams([Card1, Card2, Card6], 'fields');

function NewsPage(props) {
  const {
    meta,
    history,
  } = props;

  const { data: rawTopics, isLoading } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      limit: LIMIT,
      include: 'image',
      fields,
      topic_type: 'news',
      with_filtered_count: 1,
    }],
    queryFn: ({ queryKey: [, method, params] }) => {
      return bebopApi[method](params).catch(err => renderError(err, history));
    },
  });

  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Новости</H1>
            <ListPage
              title={`Новости науки и техники ${new Date().getFullYear()}`}
              description='Новости на Indicator.ru - последние новости об открытиях российских и зарубежных ученых, острые дискуссии об организации науки в России и взаимодействии науки и бизнеса, собственные рейтинги российских ученых, научных организаций и инновационных компаний.'
              rawData={rawTopics}
              meta={meta}
              limit={LIMIT}
              isLoading={isLoading}
            >
              {({ content }) => {
                return (
                  <RubricTopics topics={content} />
                );
              }}
            </ListPage>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

NewsPage.propTypes = {
  meta: PropTypes.shape({
    filtered_count: PropTypes.number,
  }),
  history: PropTypes.object,
};

export default NewsPage;

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';
import CardEventMain from 'site/cards/CardEventMain';
import CardPhotoMain from 'site/cards/CardPhotoMain';

import { RUBRICS } from 'site/constants';


const card1Props = {
  fields: filterRequiredParams([Card1], 'fields'),
  include: filterRequiredParams([Card1], 'include'),
};

export default function useMainContent(history) {
  // mainTopics
  const {
    data: mainTopics,
    isLoading: mainTopicsAreLoading,
  } = useRequest(
    topicsQuery({
      list: 'main',
      sort: 'list',
      include: filterRequiredParams([Card2, Card3], 'include'),
      fields: filterRequiredParams([Card1, Card3], 'fields'),
      history,
    }, {
      select: denormalizeData,
    }),
  );

  // newsTopics
  const {
    data: newsTopics,
    isLoading: newsTopicsAreLoading,
  } = useRequest(
    topicsQuery({
      limit: 6,
      topic_type: 'news',
      excluded_ids: mainTopics?.map(item => item.id).join(','),
      history,
      ...card1Props,
    }, {
      enabled: !mainTopicsAreLoading,
      select: denormalizeData,
    }),
  );

  const results = useRequest({
    queries: [
      // discussion
      topicsQuery({
        limit: 5,
        tag: 'debate-club',
        include: 'image,rubric',
        fields: filterRequiredParams([Card2, Card5, Card1], 'fields'),
        history,
      }),
      // events
      topicsQuery({
        list: 'events',
        sort: 'list',
        limit: 5,
        fields: filterRequiredParams([CardEventMain], 'fields'),
        include: filterRequiredParams([CardEventMain], 'include'),
        history,
      }),
      // popular
      topicsQuery({
        limit: 5,
        sort: '-views_count',
        ...card1Props,
        history,
      }),
      // dailyPhoto
      topicsQuery({
        limit: 1,
        topic_type: 'daily_photo',
        fields: filterRequiredParams([CardPhotoMain], 'fields'),
        include: filterRequiredParams([CardPhotoMain], 'include'),
        history,
      }),
    ],
  });

  const rubricsResult = useRequest({
    queries: RUBRICS.map((rubric) => topicsQuery(
      {
        rubric: rubric.slug,
        limit: 4,
        fields: filterRequiredParams([Card1, Card2, Card4], 'fields'),
        include: filterRequiredParams([Card1, Card2, Card4], 'include'),
        history,
      },
      {
        key: ['bebopApi', 'getTopics', rubric.slug, 'mainPage'],
        select: (data) => ({ ...rubric, topics: denormalizeData(data) }),
      }
    )),
  });

  const rubricTopicsAreLoading = rubricsResult?.some((topic) => topic?.isLoading);

  const {
    data: [
      discussion,
      events,
      popular,
      dailyPhoto,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (mainTopicsAreLoading || newsTopicsAreLoading || resultsAreLoading || rubricTopicsAreLoading) {
    return { isLoading: true };
  }

  return {
    mainTopics,
    newsTopics,
    discussion,
    events,
    popular,
    dailyPhoto,
    rubrics: rubricsResult.map(rubric => rubric?.data),
  };
}

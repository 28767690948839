import PropTypes from 'prop-types';

import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import useRequest from 'core/hooks/useRequest';

import { filterRequiredParams } from 'core/utils/api';

import H1 from 'core/components/H1';
import SocialShare from 'site/components/SocialShare';
import { Block, Section } from 'core/components/Grid';
import TagTopics from 'core/components/TagTopics';
import PageLoader from 'core/components/Loader/PageLoader';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import TopicHeaderImage from 'site/pages/topic/TopicHeaderImage';
import { Indent } from 'site/components/Wrappers';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

const LIMIT = 12;


function TagPage({ location, history, match }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location,
      history,
      match,
      limit: LIMIT,
      include: 'image',
      fields: filterRequiredParams([Card1, Card2, Card6], 'fields'),
    }),
  ] });

  if (tagIsLoading || topicsAreLoading) return <PageLoader />;

  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <TagTopics
              tag={tag}
              topics={rawTopics}
              limit={LIMIT}
              isLoading={false}
              titleComponent={({ children, tag: tagItem }) => (
                <>
                  <H1>#{children}</H1>
                  <SocialShare />
                  {tagItem?.relationships?.image?.data && (
                    <>
                      <Indent top={25} />
                      <TopicHeaderImage content={tagItem} />
                    </>
                  )}
                </>
              )}
              headerSpacing={25}
            >
              {RubricTopics}
            </TagTopics>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

TagPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default TagPage;

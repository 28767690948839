import { Fragment } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { Redirect } from 'core/libs/router';

import { filterRequiredParams } from 'core/utils/api';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import { Block, Section } from 'core/components/Grid';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import H1 from 'core/components/H1';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { Indent } from 'site/components/Wrappers';
import Boroda from 'site/components/Boroda';
import Select, { Option } from 'site/components/Select';

import CardEventList from 'site/cards/CardEventList';

import { EVENTS_PAGE_LIMIT } from 'site/constants';

import EventsTopics from './EventsTopics';

import cities from './cities';

import styles from './index.styl';


const fields = filterRequiredParams([CardEventList], 'fields');

function onCityChange(history, event) {
  event.preventDefault();
  history.push(`/events?${queryString.stringify({
    city_slug: event.target.value,
  })}`);
}

function EventsPage(props) {
  const {
    history,
    breakpoint,
    isDesktop,
    location,
  } = props;
  const citySlug = queryString.parse(location.search).city_slug;

  const { data: topics, isLoading } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      limit: EVENTS_PAGE_LIMIT,
      include: 'image,rubric,event',
      fields,
      sort: '-event.started_at',
      topic_type: 'event',
      with_filtered_count: 1,
      plugin: JSON.stringify({
        event: {
          city_slug: citySlug,
        },
      }),
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
  });

  if (citySlug === 'all') {
    return <Redirect to='/events' />;
  }

  return (
    <Fragment>
      <LightPageWrapper>
        <Section>
          <Block>
            <div className={styles.header + ' ' + styles[breakpoint]}>
              <H1>События</H1>
              <Mobile><Indent top={10} /></Mobile>
              <Select
                defaultValue={citySlug ? citySlug : 'all'}
                onChange={onCityChange.bind(this, history)}
              >
                <Option value='all'>Все</Option>
                {cities.map(city => (
                  <Option
                    key={city.slug}
                    value={city.slug}
                  >
                    {city.title}
                  </Option>
                ))}
              </Select>
            </div>
            {!isLoading &&
              <EventsTopics
                topics={topics}
                isDesktop={isDesktop}
                isLoading={isLoading}
              />
            }
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Fragment>
  );
}

EventsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

export default withBreakpoint(EventsPage);

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import useRequest from 'core/hooks/useRequest';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import { filterRequiredParams } from 'core/utils/api';

import AuthorPage from 'core/components/AuthorPage';
import { Block, Section } from 'core/components/Grid';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';
import PageLoader from 'core/components/Loader/PageLoader';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';


import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';


const LIMIT = 15;

const breakpointCards = {
  [DESKTOP]: Card2,
  [MOBILE]: Card1,
};

const include = filterRequiredParams([Card1, Card2], 'include');
const fields = filterRequiredParams([Card1, Card2], 'fields');

function Author(props) {
  const { breakpoint } = props;

  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      limit: LIMIT,
      include,
      fields,
      card: breakpointCards[breakpoint],
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            {authorIsLoading && <PageLoader />}
            {!authorIsLoading &&
              <AuthorPage
                author={author}
                topics={topics}
                card={breakpointCards[breakpoint]}
                isLoading={topicsAreLoading}
                columns={3}
                grid
              />
            }
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

Author.propTypes = {
  breakpoint: breakpointPropTypes(),
};

export default (withBreakpoint(Author));

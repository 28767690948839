import './index.styl';

export default function NativeListingSpecMainStyles(Component) {
  return function NativeListingComponent(props) {
    return (
      <div className='native-listing-spec-main'>
        <Component {...props} />
      </div>
    );
  };
}

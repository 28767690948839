import { denormalizeData } from 'core/utils/api';

import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { Block, Section } from 'core/components/Grid';
import H1 from 'core/components/H1';
import Page from 'core/components/Page';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import TagsContent from './TagsContent';

const LIMIT = 100;

function TagsPage() {
  const { data: tags, isLoading } = useRequest({
    queryKey: ['bebopApi', 'getTags', {
      with_filtered_count: 1,
      sort: 'title',
      limit: LIMIT,
    }],
    queryFn: async ({ queryKey: [, method, params] }) => {
      try {
        const rawFirstTags = await bebopApi[method](params);
        const firstTags = denormalizeData(rawFirstTags);
        const count = rawFirstTags.meta?.filtered_count || 0;

        if (count <= LIMIT) return firstTags;

        const iterations = Math.ceil(count / LIMIT);
        const payloads = [];

        for (let i = 1; i < iterations; i++) {
          payloads.push(
            bebopApi[method]({
              offset: i * LIMIT,
              sort: 'title',
              limit: LIMIT,
            })
          );
        }
        const results = await Promise.all(payloads);
        return results.reduce((stack, item) => {
          return stack.concat(denormalizeData(item));
        }, firstTags);
      } catch (error) {
        console.error('tags error', error);
        return [];
      }
    },
  });

  return (
    <Page
      title='Цифровая энциклопедия'
      description='Цифровая энциклопедия на Indicator.ru - последние новости об открытиях российских и зарубежных ученых, острые дискуссии об организации науки в России и взаимодействии науки и бизнеса, собственные рейтинги российских ученых, научных организаций и инновационных компаний.'
    >
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Цифровая энциклопедия</H1>
            {!isLoading && <TagsContent tags={tags} />}
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Page>
  );
}

export default TagsPage;

import PropTypes from 'prop-types';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Block, Section } from 'core/components/Grid';
import Feed from 'core/components/Feed';
import SideColumn from 'core/components/SideColumn';
import PageLoader from 'core/components/Loader/PageLoader';

import {
  SuperFooter,
  Parallax,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecMain,
} from 'site/components/Ads/mobile';
import FooterAd from 'site/components/Ads/FooterAd';
import { PageIndent, Indent } from 'site/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import CardPhotoMain from 'site/cards/CardPhotoMain';

import Glavnoe from './Glavnoe';
import DiscussionClub from './DiscussionClub';
import MainEvents from './MainEvents';
import MainRubrics from './MainRubrics';
import MainPopular from './MainPopular';

import useMainContent from './useMainContent';


function MainPage(props) {
  const {
    isMobile,
    history,
  } = props;

  const {
    isLoading,
    mainTopics,
    newsTopics,
    discussion,
    events,
    popular,
    dailyPhoto,
    rubrics,
  } = useMainContent(history);

  const horisontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const vertical = isMobile ? 30 : 40;

  if (isLoading) return <PageLoader />;

  return (
    <Page>
      <PageIndent>
        <Section>
          <Block>
            <AdWrapper bottom={vertical}>
              <Glavnoe content={{ main: mainTopics, news: newsTopics }} />
              <Indent top={vertical} />
              <Mobile>
                <Listing1 />
              </Mobile>
              <DiscussionClub content={discussion} />
              <Indent top={vertical} />
              <Desktop>
                <SuperFooter />
              </Desktop>
              <MainEvents content={events} />
              <Desktop><Indent top={vertical} /></Desktop>
            </AdWrapper>
          </Block>
          <InnerSideColumn />
        </Section>
        <Desktop>
          <AdWrapper
            left={-horisontal}
            right={-horisontal}
            bottom={vertical}
          >
            <Parallax />
          </AdWrapper>
        </Desktop>
        <Mobile>
          <AdWrapper top={vertical / 2} bottom={vertical}>
            <ListingSpecMain />
          </AdWrapper>
        </Mobile>
        <Section>
          <Block>
            <Feed
              content={dailyPhoto}
              card={CardPhotoMain}
              interitemSpacing={0}
            />
          </Block>
          <SideColumn>
            <MainPopular content={popular} />
          </SideColumn>
        </Section>
      </PageIndent>
      <MainRubrics content={rubrics} />
      <FooterAd />
    </Page>
  );
}

MainPage.propTypes = {
  history: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MainPage);

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import isSameDay from 'date-fns/isSameDay';

import { dateFormat } from 'core/utils/dates';

import ListPage from 'core/components/ListPage';
import { Desktop, Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import H4 from 'core/components/H4';
import Text from 'core/components/Text';

import { Indent } from 'site/components/Wrappers';
import {
  SuperFooter,
  Context,
} from 'site/components/Ads/desktop';
import {
  Listing1,
  ListingSpecEvents,
} from 'site/components/Ads/mobile';

import CardEventList from 'site/cards/CardEventList';

import { EVENTS_PAGE_LIMIT } from 'site/constants';

import styles from './index.styl';


const format = date => {
  if (isSameDay(new Date(), new Date(date))) {
    return 'Сегодня';
  }

  return dateFormat(date, 'dd MMMM');
};

export default function EventsPage(props) {
  const {
    topics,
    isDesktop,
    isLoading,
  } = props;

  const spacing = isDesktop ? 35 : 20;

  let prevDate;

  return (
    <ListPage
      title={`События науки и техники ${new Date().getFullYear()}`}
      // description='TODO'
      rawData={topics}
      limit={EVENTS_PAGE_LIMIT}
      isLoading={isLoading}
    >
      {({ content }) => {
        if (!content.length) {
          return (
            <Fragment>
              <Indent top={spacing} />
              <Text>Нет событий</Text>
            </Fragment>
          );
        }

        return (
          <Fragment>
            {content.map((topic, index) => {
              const started = get(topic, 'relationships.event.data.attributes.started_at');
              const sameDay = prevDate && started && isSameDay(new Date(prevDate), new Date(started));
              prevDate = started;

              return (
                <Fragment key={index}>
                  <Indent top={spacing} />
                  {!sameDay && (
                    <H4 className={styles.date}>
                      {format(started)}
                      <Indent top={20} />
                    </H4>
                  )}
                  <CardEventList content={topic} />
                  {index === 2 && (
                    <AdWrapper top={spacing}>
                      <Desktop><SuperFooter /></Desktop>
                      <Mobile><Listing1 /></Mobile>
                    </AdWrapper>
                  )}
                  {index === 6 && (
                    <AdWrapper top={spacing}>
                      <Desktop><Context /></Desktop>
                      <Mobile><ListingSpecEvents /></Mobile>
                    </AdWrapper>
                  )}
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    </ListPage>
  );
}

EventsPage.propTypes = {
  topics: PropTypes.object,
  isDesktop: PropTypes.bool,
  isLoading: PropTypes.bool,
};

import useRequest from 'core/hooks/useRequest';

import { rawAuthorsQuery } from 'core/queries/authorsPage';

import H1 from 'core/components/H1';
import { Block, Section } from 'core/components/Grid';
import AuthorsPage from 'core/components/AuthorsPage';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { Indent } from 'site/components/Wrappers';


function Authors(props) {
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Авторы</H1>
            <Indent top={20} />
            <AuthorsPage rawAuthors={rawAuthors}  isLoading={isLoading} />
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

export default Authors;

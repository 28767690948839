import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';
import useRequest from 'core/hooks/useRequest';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';
import { topicsQuery } from 'core/queries/topics';

import Rubric from 'core/components/Rubric';
import { Block, Section } from 'core/components/Grid';
import PageLoader from 'core/components/Loader/PageLoader';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';
import CardPhotoMain from 'site/cards/CardPhotoMain';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


function RubricPage(props) {
  const { location, match, history } = props;

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
    { data: dailyPhoto, isLoading: dailyPhotoLoading },
  ] = useRequest({
    queries: [
      rawRubricQuery({
        match,
        history,
      }),
      rawTopicsQuery({
        location,
        match,
        history,
        limit: RUBRICS_PAGE_LIMIT,
        topic_type: '-daily_photo,event',
        include: 'image',
        fields: filterRequiredParams([Card1, Card2, Card6], 'fields'),
      }),
      topicsQuery({
        limit: 1,
        topic_type: 'daily_photo',
        fields: filterRequiredParams([CardPhotoMain], 'fields'),
        include: filterRequiredParams([CardPhotoMain], 'include'),
        history,
      }),
    ],
  });

  return (
    <>
      <LightPageWrapper>
        <Section>
          <Block>
            {(rubricIsLoading || topicsAreLoading) ?
              <PageLoader />
              :
              <Rubric
                rawRubric={rawRubric}
                rawTopics={rawTopics}
                limit={RUBRICS_PAGE_LIMIT}
                isLoading={topicsAreLoading}
              >
                {({ topics, rawRubric: rubric }) => (
                  <RubricTopics
                    rubric={rubric.data.attributes.slug}
                    topics={topics}
                    dailyPhoto={dailyPhoto}
                    dailyPhotoLoading={dailyPhotoLoading}
                  />
                )}
              </Rubric>
            }
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default RubricPage;
